import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const ThemeCompleted = ({
  currentThemeUUID,
  difficulty,
  themeCompleted,
  isGameCompleted,
  theme,
  themes,
}) => {
  const BACKEND_ADDR = process.env["REACT_APP_BACKEND_ADDR"] || "/server";

  const [canSaveCompleted, setCanSaveCompleted] = useState(false);

  const getUserId = useCallback(async () => {
    let userId;
    const savedPreference = localStorage.getItem("uuid");
    if (savedPreference !== null && savedPreference !== "undefined") {
      userId = JSON.parse(savedPreference);
    } else {
      userId = await axios
        .get(`${BACKEND_ADDR}/uuid`)
        .then((response) => {
          if (response.data.error === undefined) {
            return response.data;
          } else {
            console.log(response.data.error);
          }
          return undefined;
        })
        .catch((error) => {
          console.error("There was an error fetching a uuid!", error);
          return undefined;
        });
    }
    localStorage.setItem("uuid", JSON.stringify(userId));
    return userId;
  }, [BACKEND_ADDR]);

  function removeTrailingEmoji(str) {
    return str.replace(/ (😊|😄|🥳|😎|😲|😱|☠️)$/, "");
  }

  const saveCompletion = useCallback(
    (userId, currentThemeUUID, difficulty) => {
      const data = {
        uid: userId,
        tid: currentThemeUUID,
        d: difficulty,
      };

      axios
        .post(`${BACKEND_ADDR}/completed`, data)
        .then((response) => {
          if (response.data.error === undefined) {
            if (response.data.emoji !== undefined) {
              const selectElement = document.getElementById("theme");
              const selectedOption =
                selectElement.options[selectElement.selectedIndex];
              selectedOption.text = removeTrailingEmoji(selectedOption.text);
              selectedOption.text += " " + response.data.emoji;
              themes[theme].completed = response.data.emoji;
            }
          } else {
            console.log(response.data.error);
          }
        })
        .catch((error) => {
          console.error("There was an error completing the level!", error);
        });
    },
    [BACKEND_ADDR, theme, themes]
  );

  useEffect(() => {
    async function f() {
      const userId = await getUserId();

      if (userId !== undefined) {
        saveCompletion(userId, currentThemeUUID, difficulty);
      }
    }
    if (canSaveCompleted && themeCompleted) {
      setCanSaveCompleted(false);
      f();
    }

    if (!themeCompleted && !isGameCompleted) {
      setCanSaveCompleted(true);
    }
  }, [
    currentThemeUUID,
    difficulty,
    isGameCompleted,
    getUserId,
    themeCompleted,
    canSaveCompleted,
    saveCompletion,
  ]);

  return null;
};

export default ThemeCompleted;
