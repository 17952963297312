import { useEffect, useState } from "react";
import axios from "axios";
import { formatIsoDate } from "../helpers/utils";

const ThemeManager = ({
  onThemesLoaded,
  onSentencesLoaded,
  currentTheme,
  themeKey,
}) => {
  const [themes, setThemes] = useState([]);

  const BACKEND_ADDR = process.env["REACT_APP_BACKEND_ADDR"] || "/server";

  useEffect(() => {
    // Fetch the themes from the Express server

    let data = {};
    const uuid = localStorage.getItem("uuid");
    if (uuid !== null && uuid !== "undefined") {
      data.uid = JSON.parse(uuid);
    }

    axios
      .post(`${BACKEND_ADDR}/themes`, data)
      .then((response) => {
        if (response.data.error === undefined) {
          setThemes(response.data);
        } else {
          console.log(response.data.error);
          setThemes(undefined);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the themes!", error);
      });
  }, [BACKEND_ADDR]);

  useEffect(() => {
    if (currentTheme === undefined) return;

    if (currentTheme.sentences === undefined) {
      // console.log("current theme has no sentences yet");

      const data = {
        id: currentTheme.uuid,
      };
      axios
        .post(`${BACKEND_ADDR}/theme`, data)
        .then((response) => {
          if (response.data.error === undefined) {
            onSentencesLoaded(response.data.sentences, themeKey);
          } else {
            onSentencesLoaded(undefined, undefined);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the themes!", error);
        });
    }
  }, [currentTheme, BACKEND_ADDR, onSentencesLoaded, themeKey]);

  useEffect(() => {
    async function fetchThemes() {
      if (themes === undefined) {
        onThemesLoaded(undefined);
        return;
      }

      if (themes.length === 0) return;

      try {
        let themesObj = {};

        for (const uuid in themes) {
          try {
            const theme = themes[uuid];

            let after = Date.parse(theme.after);

            if (isNaN(after)) {
              throw new Error("Failed to parse time");
            }
            const d = new Date(after);
            const isoDate = formatIsoDate(d);
            themesObj[`${isoDate}: ${theme.name}`] = {
              uuid: uuid,
              sentences: theme.sentences,
              completed: theme.completed,
            };
          } catch (err) {
            console.log("Error parsing after for", uuid);
          }
        }

        onThemesLoaded(themesObj); // Notify parent that themes are loaded
      } catch (error) {
        console.error("Error loading themes:", error);
      }
    }

    fetchThemes();
  }, [onThemesLoaded, themes]);

  return null;
};

export default ThemeManager;
